
<template>
  <Layout>
    <h6 class="text-center" style="color: gray">Available campaigns</h6>

    <div class="text-center text-danger my-5" v-if="loading">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <div v-else>
      <div v-if="campaignData.length === 0">
        <h6 class="text-center">No Campaign available</h6>
      </div>
      <div
        v-else
        class="row"
        style="margin: 0; border-top: none; padding-bottom: 18px"
      >
        <div class="col-12">
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <!-- <div class="col-sm-12 col-md-6">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-right"
            >
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                  type="search"
                  placeholder="Search..."
                  class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div> -->
            <!-- End search -->
          </div>
          <!-- Table -->

          <div class="row">
            <div
              class="col-sm-6 col-lg-4"
              v-for="data in campaignData"
              :key="data._id"
            >
              <a @click="campignSelected(data)">
                <div class="card p-4" v-if="data">
                  <div>
                    {{ data.company.name }} <br />
                    Sticker Type
                    <strong>{{ data.plan.sticker_type }}</strong>
                    <div style="font-size: 12px">
                      Phone: +{{ data.company.user_id.country_code }}-{{
                        data.company.user_id.phone_number
                      }}
                      <br />
                      Email: {{ data.company.user_id.email }}
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { campaigns } from "@/config/api/campaigns";
import Layout from "../../layouts/main";

export default {
  props: ["status"],
  components: { Layout },
  data() {
    return {
      campaignData: [],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      loading: false,
      pageOptions: [10, 25, 50, 100],
    };
  },
  computed: {
    rows() {
      return this.campaignData.length;
    },
  },
  mounted() {},
  created() {
    this.getAllCampigns();
  },
  methods: {
    getAllCampigns() {
      this.isBusy = true;
      const api = campaigns.list;
      api.params = {
        status: 1,
      };
      console.log(api);
      this.loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.campaignData = res.data.message.totalData;
          this.totalRows = res.data.message.totalCount.count;
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    campignSelected(row) {
      this.$router.push({
        name: "DesignerCampaignsDetails",
        params: { id: row._id },
      });
    },
  },
};
</script>
<style>
</style>